import { Component, OnInit, ViewChild } from '@angular/core';
import { routes } from '@app/consts';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { ReviewStatusReportService } from '../../services/review-status-report.service';
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { GridState } from '@app/shared/services/grid-state/grid-state.model';
import { from } from 'rxjs';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { set } from 'lodash';
@Component({
    selector: 'app-review-status-details-data-grid',
    templateUrl: './review-status-report-details-data-grid.component.html',
    styleUrls: ['./review-status-report-details-data-grid.component.scss']
})
export class ReviewStatusReportDetailsDataGridComponent implements OnInit {
    @ViewChild('excelexport') excelexport: any;
    public grid: GridComponent;
    public routes: typeof routes = routes;
    public bindingType = 'array';
    public gridDataResult: GridDataResult;
    excelData: any;
    gridId: string = 'ReviewStatusReportDetailsDataGrid';
    isLoading: boolean;
    pageSize: number = 20;
    skip: number = 0;

    sortString: string;
    filterString: string;
    filterToggleDetails: {
        text: string,
        checked: boolean
    } = {
            text: 'FilterInactive',
            checked: true
        }

    excelColumns: any[] = [
        { field: 'employee', title: this.translate.instant('ReviewStatusReport-Employee') },
        { field: 'position', title: this.translate.instant('ReviewStatusReport-Position') },
        { field: 'managers', title: this.translate.instant('ReviewStatusReport-Managers') },
        { field: 'reviewPlan', title: this.translate.instant('ReviewStatusReport-ReviewPlan') },
        { field: 'reviewer', title: this.translate.instant('ReviewStatusReport-Reviewer') },
        { field: "status", title: "Status" },
    ];

    public columns: any[] = [
        { field: 'employee', title: this.translate.instant('ReviewStatusReport-Employee'), localizationCode: 'ReviewStatusReport-Employee', type: 'employee' },
        { field: 'position', title: this.translate.instant('ReviewStatusReport-Position'), localizationCode: 'ReviewStatusReport-Position' },
        { field: 'managers', title: this.translate.instant('ReviewStatusReport-Managers'), localizationCode: 'ReviewStatusReport-Managers', type: 'employees' },
        { field: 'reviewPlan', subField: 'name', title: this.translate.instant('ReviewStatusReport-ReviewPlan'), localizationCode: 'ReviewStatusReport-ReviewPlan' },
        { field: 'reviewer', title: this.translate.instant('ReviewStatusReport-Reviewer'), localizationCode: 'ReviewStatusReport-Reviewer', type: 'employee' },
        { field: "status", subField: "name", title: "Status", localizationCode: 'ReviewStatusReport-Status', type: 'chip', chipValue: ['Complete', 'Archived', 'In Progress', 'Pending'] },
    ];

    sortableColumns: any[] = [
        { field: 'employee', sortValue: ['employee.firstName', 'employee.lastName'] },
        { field: 'reviewPlan', sortValue: 'reviewPlan.name' },
        { field: 'status', sortValue: 'status.name' },
    ];

    filterCategories: any[] = [
        { field: "employee.firstname", title: this.translate.instant('EmployeeFirstname'), type: "String", dataType: 'String' },
        { field: "employee.lastname", title: this.translate.instant('EmployeeLastname'), type: "String", dataType: 'String' },
        { field: "reviewPlan.name", title: this.translate.instant('ReviewStatusReport-ReviewPlan'), dataType: "SpecialLookup", lookupCode: 'REVIEW_PLAN'},
        { field: 'status.name', title: this.translate.instant('ReviewStatusReport-Status'), type: 'String', dataType: 'String' },
    ];
    searchValue: any;
    searchFilterString: string;
    getRequest: any;
    error: boolean;

    constructor(
        private translate: TranslateService,
        private overlayService: OverlayService,
        private reviewStatusReportService: ReviewStatusReportService,
    ) {
        this.getData();

        // Bind 'this' explicitly to capture the execution context of the component.
        this.allData = this.allData.bind(this);
    }


    ngOnInit(): void {
    }

    public allData(){
        this.overlayService.show('Loading');

        from(this.reviewStatusReportService.getAllReportData(this.sortString, this.filterString))
        .subscribe({
            next: (res) => {
                this.excelData = res.data;

                this.excelData.map((item) => {
                    item.position = item.positions.map((position) => {
                        return position.name;
                    }
                    ).join(', ');
                    item.employee = `${item.employee.firstName} ${item.employee.lastName}`;
                    item.managers = item.managers.map((manager) => `${manager.firstName} ${manager.lastName}`).join(', ');
                    item.reviewer = `${item.reviewer.firstName} ${item.reviewer.lastName}`;
                    item.reviewPlan = item.reviewPlan.name;
                    item.status = item.status.name;
                });

                // if I dont have this timeout first time I download the report the data will be blank
                setTimeout(() => {
                    this.excelexport.save();
                }, 1000);
            },
            error: (e) => {
                this.overlayService.hide();
            },
            complete: () => this.overlayService.hide()
        });
    }

    getData(): void {
        this.isLoading = true;
        this.getRequest = this.reviewStatusReportService.getReportData(this.pageSize, this.skip, this.sortString, this.filterString)
            .subscribe(
                {
                    next: (v) => {
                        this.gridDataResult = {
                            data: v.data,
                            total: v.total,
                        }

                        this.gridDataResult.data.map((item) => {
                            item.position = item.positions.map((position) => {
                                return position.name;
                            }
                            ).join(', ');
                        });
                    },
                    error: (e) => {
                        this.error = true;
                        this.isLoading = false;
                    },
                    complete: () => this.isLoading = false
                }
            );
    }

    public pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.pageSize = event.take;
        this.getData();
    }

    filterCallback(filterString: string) {
        this.filterString = filterString;
        this.skip = 0;
        this.getData();
    }

    search(searchValue) {
        this.searchValue = searchValue;
        this.searchFilterString = `(Name like "${this.searchValue}")`;
        this.getRequest.unsubscribe();
        this.getData();
    }

    updateFilterToggleDetails(toggleChange: MatSlideToggleChange) {
        this.filterToggleDetails.checked = toggleChange.checked;
        this.getData();
    }

    gridStateChange(gridState: GridState) {
        if (this.pageSize !== gridState.pageSize) {
            this.pageSize = gridState.pageSize;
        }
        if (this.skip !== gridState.skip) {
            this.skip = gridState.skip;
        }
        if (this.sortString !== gridState.sortString) {
            this.sortString = gridState.sortString;
        }
        if (this.filterString !== gridState.filter) {
            this.filterString = gridState.filter;
            this.skip = 0;
        }

        if (gridState.filterToggleDetails === null) {
            this.filterToggleDetails.checked = false;
            this.skip = 0;
        } else if (this.filterToggleDetails.checked !== gridState.filterToggleDetails.checked) {
            this.filterToggleDetails.checked = gridState.filterToggleDetails.checked;
            this.skip = 0;
        }

        if (this.searchValue !== gridState.searchValue) {
            let variants = [];
            this.searchFilterString = '';
            this.searchValue = gridState.searchValue;

            if (this.searchValue !== null) {
                variants = this.searchValue.split(' ').filter(value => value);
            }

            variants.map(variant => {
                if (variant) {
                    if (this.searchFilterString.length > 2) {
                        this.searchFilterString += ' AND ';
                    }
                    this.searchFilterString += `(Name like "${variant}")`;
                }
            });

            this.skip = 0;
        }

        this.getRequest.unsubscribe();
        this.getData();
    }
}
