import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api_routes } from '@app/consts';
import { Observable } from 'rxjs';
import { EnvironmentService } from '@app/core/services/environment.service';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { ReviewStatusReportItem } from '../models/review-status-report.model';


const headers = new HttpHeaders({
    'Content-Type': 'application/json',
});

@Injectable({
    providedIn: 'root'
})
export class ReviewStatusReportService {

    constructor(
        private http: HttpClient,
        private envService: EnvironmentService
    ) {
    }

    getReportData(take?: number, skip?: number, sort?: string, filter?: string, asOf?: string): Observable<PagedData<ReviewStatusReportItem>> {
        let params = new HttpParams();
        sort ? params = params.append('sort', sort) : null;
        filter ? params = params.append('filter', filter) : null;
        asOf ? params = params.append('asOf', asOf) : null;
        skip ? params = params.append('skip', skip) : null;
        take ? params = params.append('take', take) : null;

        return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.REPORTS}/${api_routes.PREDEFINED}/${api_routes.REVIEW_STATUS_REPORT}`, {
            headers: headers,
            params: params
        });
    }

    async getAllReportData(sort?: string, filter?: string): Promise<PagedData<ReviewStatusReportItem>> {
        let take = 100;
        let skip = 0;
        let allData = {
            skip: null,
            take: null,
            totalPages: null,
            total: null,
            data: []
        }

        while (allData.total === null || skip < allData.total) {
            const res = await this.getReportData(take, skip, sort, filter).toPromise();

            if (res && res.data && res.data.length > 0) {
                allData.data = allData.data.concat(res.data);
                skip += take;
                allData.total = res.total;
            } else {
                break;
            }
        }

        return allData;
    }
}
