import { Component, OnInit } from '@angular/core';
import { features, routes } from '@app/consts';
import { Router } from "@angular/router";
import { FeatureService } from '@app/core/services/feature.service';
import { SecurityProtectedBase } from '@app/shared/components/security-protected/security-protected';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { OrganizationTypesDialogComponent } from '@app/modules/organization-structure/components/organization-types-dialog/organization-types-dialog.component';
import { OrganizationTypeEditorDialogComponent } from '@app/modules/organization-structure/components/organization-types-editor-dialog/organization-type-editor-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { OrganizationStructureService } from './services/organization-structure.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { finalize } from 'rxjs/operators';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { defer, forkJoin } from 'rxjs';
import { Organization } from './models/organization-structure.model';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { GridState } from '@app/shared/services/grid-state/grid-state.model';
import { SortDescriptor } from '@progress/kendo-data-query';

@Component({
    selector: 'app-organization-structure',
    templateUrl: './organization-structure.component.html',
    styleUrls: ['./organization-structure.component.scss']
})
export class OrganizationStructureComponent extends SecurityProtectedBase implements OnInit, OrganizationNavListener {
    gridId: string = 'organization-structure';
    refreshView: boolean = true;
    public routes: typeof routes = routes;
    public features: typeof features = features;
    formId: string = 'frm_qK0FYWUEQe7FOz';
    dialogRef: MatDialogRef<FormGeneratorDialogComponent>;
    public columns: any[] = [
        {field: 'name', title: 'Name'},
        {field: 'organizationType', subField: 'name', title: 'Organization Type'},
        {field: 'parentOrganization', subField: 'name', title: 'Parent Organization'},
        {field: 'startDate', title: 'Start Date', type:'date'},
        {field: 'endDate', title: 'End Date', type:'date'},
    ];
    filterCategories: any[] = [
        {field: 'name', title: 'Name', dataType: 'String'},
        {field: 'OrganizationType.Name', title: 'Organization Type', dataType: 'String'},
        {field: 'ParentOrganization.Name', title: 'Parent Organization', dataType: 'String'},
        {field: 'startDate', title: 'Start Date', type:'date', dataType: 'Date'},
        {field: 'endDate', title: 'End Date', type:'date', dataType: 'Date'},
    ];
    sortableColumns: any[] = [
        { field: "name", sortValue: "name" },
        { field: "organizationType", sortValue: "organizationType.name" },
        { field: "parentOrganization", sortValue: "parentOrganization.name" },
        { field: "startDate", sortValue: "startDate" },
        { field: "endDate", sortValue: "endDate" },
    ];

    public bindingType: String = 'array';
    public gridDataResult: GridDataResult;
    public selectedPositions: any[] = [];
    isLoading: boolean;
    pageSize: number = 20;
    skip: number = 0;
    public searchFilterString: string;
    public searchValue: string;
    private getRequest: any;
    filterString: string;
    sort: SortDescriptor[] = [
        {
            field: 'name',
            dir: 'asc'
        }
    ];
    sortString: string = "name-asc";
    clearSelectedItems: boolean = false;

    filterToggleDetails: {
        text: string,
        checked: boolean
    } = {
        text: 'FilterInactive',
        checked: true
    }

    constructor(
        featureService: FeatureService,
        private dialog: MatDialog,
        private translate: TranslateService,
        private organizationService: OrganizationStructureService,
        private overlayService: OverlayService,
        private snackbarService: SnackbarService,
        public router: Router,
    ) {
        super(featureService, features.ORGANIZATION);
    }

    ngOnInit(): void {
        this.getOrganizations();
    }

    redirectOrgChart(){
        this.router.navigate([`${routes.REPORTS}${routes.ORG_CHART}`, {type: "organizations", isEditable: "true"}]);
    }

    showOrganizationTypesModal() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {};

        const dialogRef = this.dialog.open(OrganizationTypesDialogComponent, dialogConfig);
        dialogRef.componentInstance.listener = this;
        dialogRef.afterClosed().subscribe(
            data => {
                if (data.showAddModal) {
                    this.showAddOrganizationTypeModal()
                }
            });
    }

    showAddOrganizationTypeModal(orgTypeId: string = null) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            orgTypeId: orgTypeId
        };

        const dialogRef = this.dialog.open(OrganizationTypeEditorDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data.showTypesModal) {
                    this.showOrganizationTypesModal();
                }
            });
    }

    openNewOrganizationDialog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            formTitle: `${this.translate.instant('NewOrganization')}`,
            formId: this.formId,
        };

        this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

        const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
            this.createNewOrganization(event)
        });
    }

    createNewOrganization(formData) {
        formData.parentOrganization = formData.parentOrganization ? formData.parentOrganization.id : null ;

        this.overlayService.show();

        this.organizationService.createOrganization(formData)
        .pipe(
          finalize(()=>{
            this.overlayService.hide();
          })
        )
        .subscribe(
          (res) => {
            this.dialogRef.close();
            this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
            this.router.navigate([`${routes.SITE_SETTINGS}${routes.ORGANIZATION}${routes.EDITOR}/${res.organizationId}`]);
          }
        );
    }

    getOrganizations(): void {
        this.isLoading = true;

        let filter;
        if(this.searchFilterString) {
            filter = this.searchFilterString;
        }
        if(this.filterString) {
            if(filter){
                filter = `${filter} AND ${this.filterString}`;
            }
            else {
                filter = `${this.filterString}`;
            }
        }

        this.getRequest = this.organizationService.getOrganizations(this.skip, String(this.pageSize), filter, this.sortString, this.filterToggleDetails.checked.toString())
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                })
            )
            .subscribe(
                res => {
                    this.gridDataResult = {
                        data: res.data,
                        total: res.total,
                    };
                }
            );
    }

    public pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.pageSize = event.take;
        this.getOrganizations();
    }

    deleteAllSelected(itemsToDelete: string[]) {
        const observables = itemsToDelete.map(item => defer(() => this.organizationService.deleteOrganization(item)));
        this.overlayService.show();
        forkJoin(observables)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe(
                (res) => {
                    this.clearSelectedItems = !this.clearSelectedItems;
                    this.getOrganizations();
                    this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
                },
                err => {
                    this.snackbarService.openSnackBar(err, 'clear', 'warn');
                }
            );
    }

    filterCallback(filterString: string) {
        this.filterString = filterString;
        this.skip = 0;
        this.getOrganizations();
    }

    search(searchValue) {
        this.searchValue = searchValue;
        this.searchFilterString = `(Name like "${this.searchValue}")`;
        this.getRequest.unsubscribe();
        this.getOrganizations();
    }

    navigateToOrganization(organization?: Organization) {
        if(organization){
          this.router.navigate([`${routes.SITE_SETTINGS}${routes.ORGANIZATION}${routes.EDITOR}/${organization?.id}`]);
        }
        else {
          this.router.navigate([`${routes.SITE_SETTINGS}${routes.ORGANIZATION}${routes.EDITOR}`]);
        }
    }

    updateFilterToggleDetails(toggleChange: MatSlideToggleChange){
        this.filterToggleDetails.checked = toggleChange.checked;
        this.getOrganizations();
    }

    gridStateChange(gridState: GridState) {
        if(this.pageSize !== gridState.pageSize) {
          this.pageSize = gridState.pageSize;
        }
        if(this.skip !== gridState.skip) {
          this.skip = gridState.skip;
        }
        if(this.sortString !== gridState.sortString) {
          this.sortString = gridState.sortString;
        }
        if(this.filterString !== gridState.filter) {
          this.filterString = gridState.filter;
          this.skip = 0;
        }

        if(gridState.filterToggleDetails === null) {
            this.filterToggleDetails.checked = false;
            this.skip = 0;
        } else if(this.filterToggleDetails.checked !== gridState.filterToggleDetails.checked) {
            this.filterToggleDetails.checked = gridState.filterToggleDetails.checked;
            this.skip = 0;
        }
        
        if(this.searchValue !== gridState.searchValue) {
          let variants = [];
          this.searchFilterString = '';
          this.searchValue = gridState.searchValue;
    
          if(this.searchValue !== null) {
            variants = this.searchValue.split(' ').filter(value => value);
          }
    
          variants.map(variant => {
              if (variant) {
                  if (this.searchFilterString.length > 2) {
                      this.searchFilterString += ' AND ';
                  }
                  this.searchFilterString += `(Name like "${variant}")`;
              }
          });
    
          this.skip = 0;
        }

        this.getRequest.unsubscribe();
        this.getOrganizations();
    }
}

export interface OrganizationNavListener {
    showOrganizationTypesModal()
    showAddOrganizationTypeModal()
}
