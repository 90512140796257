<mat-card>
    <app-data-grid-comp
        [gridId]="gridId"
        [isLoading]="isLoading"
        [height]="750"
        [columns]="columns"
        [gridDataResult]="gridDataResult"
        [pageSize]="pageSize"
        [skip]="skip"
        category="ReviewStatusReport"
        export="customExcel"
        [sortable]="{
              mode: 'multiple'
          }"
        hideActionButtons="true"
        filterable="false"
        [filterCategories]="filterCategories"
        [sortableColumns]="sortableColumns"
        (emitGridStateChangeEvent)="gridStateChange($event)"
        (emitExportEvent)="allData()"
    ></app-data-grid-comp>

    <kendo-excelexport
      [data]="excelData"
      fileName="report.xlsx"
      #excelexport
    >
        <kendo-excelexport-column
            *ngFor="let column of excelColumns"
            [field]="column.field"
            [title]="column.title"
        >
        </kendo-excelexport-column>
    </kendo-excelexport>
</mat-card>

